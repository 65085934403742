import Product from "./product";
import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";
import {useEffect, useState} from "react";
import Link from "next/link";
import {useRouter} from "next/router";
import storevio from "@lib/api";
import {formatPrice} from "@lib/formatPrice";
import useCustomer from "@framework/customer/use-customer";


export default function RecommendedProducts({...props}) {

    const OwlCarousel = dynamic(() => import("react-owl-carousel"), {ssr: false});
    const { t } = useTranslation('common');
    const [products, setProducts] = useState<any[]>([]);
    const router = useRouter()
    const customer = useCustomer();


    useEffect(() => {
        async function fetchData() {
            setProducts(await storevio.getHomepage(router.locale))
        }
        fetchData().then();
    }, [router.locale, customer.data])

    return (
        <section className="products-section">
            <h2 className="title">
                {t("Doporučujeme")}
            </h2>
            {products.length > 0 && <div className="products">
                <OwlCarousel className='products-carousel owl-carousel' responsive={{
                    0: {
                        items: 1
                    },

                    768: {
                        items: 2
                    },

                    1024: {
                        items: 4
                    }
                }} items={4} margin={0} nav>

                        {products.map((product, index) => <div key={index} className="slide"><Product
                            id={product.product.id}
                            product={product.product}
                            name={product.product.name}
                            slug={product.product.slug}
                            price={product.product.price}
                            options={props.options}
                            tags={product.product.tags}
                            hasVariants={product.product.hasVariants}
                            variants={product.product.variants}
                            image={product.product.mainPhoto}
                        /></div>)}


                </OwlCarousel>

            </div>}
            {products.length > 0 && <div className="more-btn">
                <Link href={"/category"}>
                    <a className="btn">
                        {t("Celá kolekce")}
                    </a>
                </Link>
            </div>}
        </section>
    );
}
