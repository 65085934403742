import useTranslation from "next-translate/useTranslation";

export default function Instagram({...props}) {

    const { t } = useTranslation('common');


    return (
        <section className="instagram-section">
            <h2 className="title">
                {t("Instagram TONAK")}
            </h2>
            <p>
                {t("Nenajdete tam králíky z klobouku, ale pouze klobouk")}
            </p>
            {/*<div className="instagram-items">
                <a href="" style={{backgroundImage: 'url(/images/instagram01.jpg)'}}>

                </a>
                <a href="" style={{backgroundImage: 'url(/images/instagram02.jpg)'}}>

                </a>
                <a href="" style={{backgroundImage: 'url(/images/instagram03.jpg)'}}>

                </a>
                <a href="" style={{backgroundImage: 'url(/images/instagram04.jpg)'}}>

                </a>
            </div>*/}

            <div className="instagram-items">
                <a href="https://www.instagram.com/p/CZROnXlqIwh/" target={"_blank"} rel="noreferrer" style={{backgroundImage: 'url(/ig/ig_1.jpeg)'}}>

                </a>
                <a href="https://www.instagram.com/p/CZKPflPKvJW/" target={"_blank"} rel="noreferrer" style={{backgroundImage: 'url(/ig/ig_2.jpeg)'}}>

                </a>
                <a href="https://www.instagram.com/p/CZERYBlKPQp/" target={"_blank"} rel="noreferrer" style={{backgroundImage: 'url(/ig/ig_3.jpeg)'}}>

                </a>
                <a href="https://www.instagram.com/p/CY_g4GTqvMj/" target={"_blank"} rel="noreferrer" style={{backgroundImage: 'url(/ig/ig_4.jpeg)'}}>

                </a>
            </div>
        </section>
    );
}
