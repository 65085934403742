import useTranslation from "next-translate/useTranslation";

export default function OurRules({...props}) {

    const { t } = useTranslation('common');


    return (
        <section className="our-rules">
            <h2 className="title">
                {t("OurRulesTitle")}
            </h2>
            <ul className="our-rules-items">
                <li>
                    <img src="/images/rule01.svg" alt=""/>
                    <span>{t("Králičí srst od českých farmářů")}</span>
                </li>
                <li>
                    <img src="/images/rule02.svg" alt=""/>
                    <span>{t("Vše vyrobeno u nás doma")}</span>
                </li>
                <li>
                    <img src="/images/rule03.svg" alt=""/>
                    <span>{t("Nechemické čištění")}</span>
                </li>
                <li>
                    <img src="/images/rule04.svg" alt=""/>
                    <span>{t("Vlna od farmářů, kteří netýrají ovce")}</span>
                </li>
                <li>
                    <img src="/images/rule05.svg" alt=""/>
                    <span>{t("100% bez výrobního odpadu")}</span>
                </li>
                <li>
                    <img src="/images/rule06.svg" alt=""/>
                    <span>{t("Ruční zpracování nejvyšší kvalita")}</span>
                </li>
            </ul>
        </section>
    );
}
