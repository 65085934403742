
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Layout from "../components/common/Layout";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import dynamic from "next/dynamic";
import useTranslation from 'next-translate/useTranslation';
import RecommendedProducts from "../components/recommendedProducts";
import InfoSection from "../components/infoSection";
import OurRules from "../components/ourRules";
import Instagram from "../components/instagram";
import {GetServerSideProps} from "next";
import Link from "next/link";
import storevio from "@lib/api";
import {useUI} from "@components/ui";
import {useRouter} from "next/router";

// @ts-ignore
 async function _getServerSideProps({locale}: GetServerSideProps) {

    const topNavLinks = await storevio.getMenu(locale!,'top')
    const bottomNavLinks = await storevio.getMenu(locale!,'bottom')
    const settings = await storevio.getSettings(locale!);
    return {
        props: {
            topNavLinks,
            bottomNavLinks,
            settings
        }
    }
}

export default function Home({...props}) {

    const OwlCarousel = dynamic(() => import("react-owl-carousel"), {ssr: false});
    const router = useRouter();
    const { t } = useTranslation('common');

    const {openModal, setModalView} = useUI()

    const openHugo = (e: any) => {
        e.preventDefault();
        setModalView("HUGO_PRODUCTS");
        openModal();
    }


    return (
      <>
        <main role="main">
            <div className="page-wrapper">
                <section className="headline">
                    <div className="headline-inner" style={{backgroundImage: 'url("/images/headline.jpg")'}}>
                            <a className="config-link" href={"#"} onClick={openHugo}>
                                {t("Virtuální zkoušeč HUGO")}
                            </a>
                        <div className="headline-content">
                            <h2>
                                {t("Objevte svůj styl")}
                            </h2>

                            <a className="btn btn-white" onClick={openHugo} href={"#"}>{t("Začít zkoušet")}</a>

                        </div>
                    </div>
                </section>


                <RecommendedProducts options={props.settings} />
                <InfoSection />

                <section className="tester-info">
                    <h2 className="title">
                        {t("Virtuální zkoušeč HUGO")}
                    </h2>
                    <p>
                        {t("virtualni_zkousec_hugo_desc")}
                    </p>
                    <div className="tester-info-blocks">
                        <div className="tester-info-block">
                            <p>
                                <img src="/images/testerinfo01.svg" />
                                {t("tester_1")}
                            </p>
                        </div>
                        <div className="tester-info-block">
                            <p>
                                <img src="/images/testerinfo02.svg" alt="02"/>
                                {t("tester_2")}
                            </p>
                        </div>
                        <div className="tester-info-block">
                            <p>
                                <img src="/images/testerinfo03.svg" alt="03"/>
                                {t("tester_3")}
                            </p>
                        </div>
                    </div>
                    <div className="more-btn">
                        <a className="btn btn-background" href={"#"} onClick={openHugo}>{t("Začít zkoušet")}</a>
                        <Link href={"/category"}>
                            <a className="btn">
                                {t("Katalog produktů")}
                            </a>
                        </Link>
                    </div>
                </section>
                <section className="products-tiles">
                    <Link href={"/category/klobouky?parameter=&parameter=402a6f6a-5ff2-4aca-aae9-fd9ed318daa1_aaaba5b4-e786-41e7-a568-1fef67d2622f"}>
                        <a>
                            <div className="tile-image" style={{backgroundImage: 'url(/images/tile01.jpg)'}} />
                            <div className="tile-content">
                                <h2>{t("Klobouky do společnosti a na významné akce")}</h2>
                                <span className="btn btn-white">{t("Vybrat vhodný")}</span>
                            </div>
                        </a>
                    </Link>
                    <Link href={(router.locale === 'en' ? '/en/' : '/') + "category?tag=50f67b98-0963-11ee-9243-0242ac110002"}>
                        <a>
                            <div className="tile-image"style={{backgroundImage: 'url(/images/bestseller.jpg)'}} />
                            <div className="tile-content">
                                <h2>{t("Bestsellery")}</h2>
                                <span className="btn btn-white">{t("Vybrat vhodný")}</span>
                            </div>
                        </a>
                    </Link>
                    <Link href={(router.locale === 'en' ? '/en/' : '/') + "category/barety-1"}>
                        <a>
                            <div className="tile-image" style={{backgroundImage: 'url(/images/barety.jpg)'}} />
                            <div className="tile-content">
                                <h2>{t("Stylové barety")}</h2>
                                <span className="btn btn-white">{t("Vybrat vhodný")}</span>
                            </div>
                        </a>
                    </Link>
                    <Link href={(router.locale === 'en' ? '/en/' : '/') + "category/cepice"}>
                        <a>
                            <div className="tile-image" style={{backgroundImage: 'url(/images/tile04.jpg)'}} />
                            <div className="tile-content">
                                <h2>{t("Pletené čepice pro každého")}</h2>
                                <span className="btn btn-white">{t("Vybrat vhodný")}</span>
                            </div>
                        </a>
                    </Link>
                </section>

                <OurRules />

                {router.locale === 'cs' && <section className="about-section">
                    <div className="about-section-right">
                        <h2>
                            Flagship Store TONAK
                        </h2>
                        <p>
                            Chcete mít jistotu, že klobouk či čepice je ta správná volba?
                            Patříte mezi ty, kteří dlouho váhají a chtějí se s někým poradit?
                            Anebo prostě preferujete kamennou prodejnu, dotek kvalitních materiálů a pocit, když odcházíte s taškou s nakoupeným zbožím?
                            Je na čase zavítat na značkovou prodejnu Tonak. Nejen, že se můžete poradit se zkušenými prodejními asistentkami, v klidu vybrat z kolekcí, které nemusí být na e-shopu, ale především získáte jistotu, ohledně vašeho výběru.
                            Prodejna nabízí široký sortiment všech druhů pokrývek hlavy - klobouky, pletené čepice a barety, letní slaměné klobouky, šité bekovky a kapitánky, cylindry, buřinky, westernové klobouky, sisaly a fascinátory.
                            O údržbě klobouku se samozřejmě dozvíte úplně vše, a budete mile překvapeni, jak je to snadné.
                            Pokud chcete vás výběr zvážit, máte možnost rezervace zboží na prodejně po dobu 48h.
                        </p>
                        <p  style={{paddingBottom: 0}}>
                            Další služby:
                        </p>
                        <ul style={{marginTop: 10}}>
                            <li>Možnost vyzvednutí zboží na prodejně při objednávce z e-shopu – 7 dnů na vyzvednutí</li>
                            <li>Zmenšující potní pásek k zakoupenému produktu zdarma</li>
                            <li>Zmenšení klobouku o jednu velikost (zapošitím potního pásku prodejní asistentkou), při koupi na počkání nebo do 2 dnů od doručení klobouku na prodejnu</li>
                        </ul>
                        <p>
                            To vše 7 dní v týdnu, v atraktivní lokalitě mezi Pařížskou ulicí a Židovskou čtvrtí.
                            Těšíme se na vaši návštěvu a u šálku dobré kávy si bezpochyby dobře vyberete.

                        </p>
                        <img src="/images/about01.jpg" alt=""/>
                    </div>
                    <div className="about-section-left">
                        <OwlCarousel className='about-section-carousel owl-carousel' items={1} margin={0} loop nav>
                            <div className="slide">
                                <img src="/images/hp/1.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>1/8</span>
                                    <p>
                                        Prodejny Flagship Store TONAK Praha a Concept Store TONAK Nový Jičín jsou
                                        otevřené pro všechny zákazníky 7 dní v týdnu. Naše prodejní asistentky jsou
                                        připraveny Vám poradit s výběrem.
                                    </p>
                                </div>
                            </div>
                            <div className="slide">
                                <img src="/images/hp/2.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>2/8</span>
                                    <p>
                                        Široký sortiment plstěných klobouků, pletených čepic, baretů, ale i šitých kloboučků a
                                        letních slaměných klobouků je k dostání na prodejnách TONAK.
                                    </p>
                                </div>
                            </div>
                            <div className="slide">
                                <img src="/images/hp/3.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>3/8</span>
                                    <p>
                                        Milovníci klobouků se neobejdou bez kloboukových krabic TONAK pro správné
                                        skladování.
                                    </p>
                                </div>
                            </div>
                            <div className="slide">
                                <img src="/images/hp/4.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>4/8</span>
                                    <p>
                                        Péče o klobouk není žádná věda. Díky kartáčkům TONAK můžete svůj klobouk
                                        pravidelně čistit a osvěžit tak jeho vzhled.
                                    </p>
                                </div>
                            </div>
                            <div className="slide">
                                <img src="/images/hp/5.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>5/8</span>
                                    <p>
                                        Individuální poradentství, profesionální přístup, exkluzivní produkty. Zažijte nákupy
                                        v TONAKu.
                                    </p>
                                </div>
                            </div>
                            <div className="slide">
                                <img src="/images/hp/6.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>6/8</span>
                                    <p>
                                        Sezónní a limitované kolekce, zájmové klobouky pro myslivce, westernové klobouky,
                                        pokrývky hlavy na svatbu či dostihy? To a mnohem více na prodejně TONAK.
                                    </p>
                                </div>
                            </div>
                            <div className="slide">
                                <img src="/images/hp/7.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>7/8</span>
                                    <p>
                                        Vysoce kvalitní vlněné čepice z naší strakonické továrny Vás zahřejí, ale jsou i
                                        exkluzivním doplňkem Vašeho outfitu.
                                    </p>
                                </div>
                            </div>
                            <div className="slide">
                                <img src="/images/hp/8.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>8/8</span>
                                    <p>
                                        Spokojený zákazník je u nás na prvním místě. Náš sortiment zahrnuje široký výběr
                                        produktů pro pány – vlněné čepice, bekovky i klobouky.
                                    </p>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </section>}

                {router.locale === 'en' && <section className="about-section">
                    <div className="about-section-right">
                        <h2>
                            Flagship Store TONAK
                        </h2>
                        <p>
                            Do you wish to be assured that a hat or a cap is the right choice?
                            Are you one of those who hesitate for a long time and wishes to consult someone?
                            Or do you just prefer visiting brick-and-mortar shop, touching quality materials and have satisfied feeling when you leave with purchased goods?
                            It`&apos;s time to visit our Tonak Flagship Store. You will be able to consult our experienced sales assistants, choose items from collections that may not be available in the e-shop in relaxed setting and above all gain confidence about your choice.
                            The store offers a wide range of all types of headwear – hats, knitted hats and berets, summer straw hats, flat caps and captain’s caps, top hats, bowler hats, western hats, headwear made of sisal fibre and fascinator hats.
                            And of course, you will find out absolutely everything about hat care and will be pleasantly surprised at how easy it is.
                            If you wish to deliberate about your choice then you have the option that the goods will be held in reserve at the store for 48 hours.
                        </p>
                        <p>
                            Additional services:
                            <ul style={{marginTop: 10}}>
                                <li>Option of collecting the goods at the store when ordering from the e-shop – 7 calendar day time limit</li>
                                <li>Shrinking sweatband with the purchased product free of charge</li>
                                <li>Reducing a hat by one size (by sewing a sweatband by sales assistant); either waiting for the alteration to be carried out at the store when buying or within 2 days of delivery of the hat to the store</li>

                            </ul>
                            All this 7 days a week, in attractive location between Pařížská Street and the Jewish Quarter.

                            We are looking forward to your visit and with a cup of good coffee you will without doubt make a good choice.


                        </p>
                        <img src="/images/about01.jpg" alt=""/>
                    </div>
                    <div className="about-section-left">
                        <OwlCarousel className='about-section-carousel owl-carousel' items={1} margin={0} loop nav>
                            <div className="slide">
                                <img src="/images/hp/1.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>1/8</span>
                                    <p>
                                        Flagship Store TONAK Praha and Concept Store TONAK Nový Jičín are open daily
                                        for all customers. Our sales assistants are happy to help you with your customer
                                        experience.
                                    </p>
                                </div>
                            </div>
                            <div className="slide">
                                <img src="/images/hp/2.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>2/8</span>
                                    <p>
                                        A wide range of felt hats, knitted caps, wollen berets, as well as sewn hats and summer
                                        straw hats are available in TONAK stores.
                                    </p>
                                </div>
                            </div>
                            <div className="slide">
                                <img src="/images/hp/3.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>3/8</span>
                                    <p>
                                        Hat lovers cannot leave TONAK store our hat boxes for proper storage.
                                    </p>
                                </div>
                            </div>
                            <div className="slide">
                                <img src="/images/hp/4.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>4/8</span>
                                    <p>
                                        Do not be afraid of hat care. Thanks to TONAK brushes, you can clean your hat
                                        regularly and refresh its appearance.
                                    </p>
                                </div>
                            </div>
                            <div className="slide">
                                <img src="/images/hp/5.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>5/8</span>
                                    <p>
                                        Individual advice, professional approach, exclusive products. It is customer shopping
                                        experience at TONAK.
                                    </p>
                                </div>
                            </div>
                            <div className="slide">
                                <img src="/images/hp/6.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>6/8</span>
                                    <p>
                                        Seasonal and limited collections, hobby hats for hunters, western hats, headwear for
                                        weddings or horse racing? This and much more at TONAK.
                                    </p>
                                </div>
                            </div>
                            <div className="slide">
                                <img src="/images/hp/7.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>7/8</span>
                                    <p>
                                        High-quality woolen caps from our Strakonice factory will keep you warm, but they
                                        are also exclusive accessories for your outfit.
                                    </p>
                                </div>
                            </div>
                            <div className="slide">
                                <img src="/images/hp/8.jpg" alt=""/>
                                <div className="slide-bottom">
                                    <span>8/8</span>
                                    <p>
                                        Satisfied customer is our first priority. Our product range includes a wide selection of
                                        products for men - woolen caps, beanies and hats.
                                    </p>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </section>}

                <Instagram />

            </div>
        </main>
      </>
  )
}

Home.Layout = Layout;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  