import useTranslation from "next-translate/useTranslation";
import Link from 'next/link';
import {useRouter} from "next/router";

export default function InfoSection({...props}) {

    const { t } = useTranslation('common');
    const router = useRouter();


    return (
        <section className="info-section">
            <div className="info-section-inner">
                <Link href={(router.locale === 'en' ? '/en/' : '/') +"category/slamene-klobouky"}>
                    <a style={{backgroundImage: 'url(/images/info1.jpg)'}}>
                        <h2>{t("Letní kolekce")}</h2>
                    </a>
                </Link>
                <Link href={(router.locale === 'en' ? '/en/' : '/') + "category/outlet"}>
                    <a style={{backgroundImage: 'url(/images/info2.jpg)'}}>
                        <h2>{t("Outlet")}</h2>
                    </a>
                </Link>
                {router.locale === 'cs' && <Link href={t('/category/darkove-poukazy')}>
                    <a style={{backgroundImage: 'url(/images/klob.jpg)'}}>
                        <div className="info-block">
                            <h2>
                                {t("Hledáte tip na dárek?")}
                            </h2>
                            <p>
                                {t("TipBoxDesc")}
                            </p>
                            <span className="btn btn-background">
                                {t("Chci poukaz")}
                            </span>
                        </div>
                    </a>
                </Link>}

                {router.locale === 'en' && <Link href={'/en/category?tag=50f67b98-0963-11ee-9243-0242ac110002'}>
		             <a style={{backgroundImage: 'url(/images/klob.jpg)'}}>
			             <div className="info-block">
				             <h2>
                             {t("Hledáte tip na dárek?")}
				             </h2>
				             <p>
                             {t("TipBoxDesc")}
				             </p>
				             <span className="btn btn-background">
                               Choose suitable
                            </span>
			             </div>
		             </a>
	             </Link>}
                <Link href={router.locale == 'en' ? '/en/page/cleaning-and-storage-hat' : "/page/cisteni-a-skladovani-klobouku"}>
                    <a style={{backgroundImage: 'url(/images/kartac.jpg)'}}>
                        <div className="info-block">
                            <h2>
                                {t("Starostlivost o klobouky")}
                            </h2>
                            <p>
                                {t("CareBoxDesc")}
                            </p>
                            <span className="btn btn-background">
                                {t("Číst více")}
                            </span>
                        </div>
                    </a>
                </Link>
            </div>
        </section>
    );
}
